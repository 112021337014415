<template>
	<div class="box">
		<div class="box-fixed ">
			<!-- <div class="box-fixed " :style="'margin-left:-' + pageWith / 2 + 'px'"> -->
			<div class="van-row--flex van-row--justify-center">
				<div class="customer-service" @click="clickDialogue" v-if="dialogueShow">
					<img :src="require('@/assets/images/common/kefu.png')" class="image" />
					<p class="text">客服</p>
				</div>
				<div class="button-group ">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		dialogueShow: {
			type: Boolean,
			default: false,
		},
		dialogueUrl: String,
	},
	data() {
		return {
			pageWith: 414,
		}
	},
	methods: {
		click() {
			this.$router.push({ name: 'paymentResult' })
		},
		clickDialogue() {
			this.$store.dispatch('getDialogueURL').then((dialogueURL) => {
				this.$util.locationHref(this.dialogueUrl || dialogueURL)
			})
		},
	},
	mounted() {
		this.pageWith = document.querySelector('.wapper').clientWidth
	},
}
</script>

<style scoped lang="scss">
.box {
	height: 1.63rem;

	&-fixed {
		z-index: 1;
		position: fixed;
		width: 100%;
		bottom: 0;
		// left: 50%;
		// max-width: $maxWidth;
		left: 0;
		width: 100%;
		padding: 0.24rem 0.53rem;
		height: 1.63rem;
		box-sizing: border-box;
		background-color: #fff;
		display: inline-block;
	}
}

.customer-service {
	width: 0.68rem;
	text-align: center;
	margin-right: 0.6rem;

	.image {
		width: 0.6rem;
	}
}

.button-group {
	@include flex_between_center;
	flex: 1;
}
</style>
