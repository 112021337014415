<template>
  <div class="timer">
    <p class="timer-item day" v-if="formatTime.day>0"><span class='number'>{{ formatTime.day }}</span>天</p>
    <p class="timer-item hour">{{ timeZeroFill(formatTime.hour) }}</p>
    <p class="timer-item symbol">:</p>
    <p class="timer-item minute">{{ timeZeroFill(formatTime.minute) }}</p>
    <p class="timer-item symbol">:</p>
    <p class="timer-item second">{{ timeZeroFill(formatTime.second) }}</p>
  </div>
</template>

<script>
import dayjs from "@/utils/date";

export default {
  name: "groupDayTimer",
  props: {
    millisecond: Boolean,
    time: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    formatTime() {
      return dayjs.formatTime(this.remain)
    },
    timeZeroFill() {
      return function (number) {
        return this.$util.zeroFill(number)
      }
    }
  },
  data() {
    return {
      keepAlivePaused: true,
      remain: 0
    }
  },
  created() {
    this.remain = this.time
    if (this.keepAlivePaused) {
      if (this.remain > 0) {
        this.counting = true;
        this.keepAlivePaused = false;
        this.tick();
      }else {
        this.setRemain(0)
      }
    }
  },

  methods: {
    tick() {
      setTimeout(() => {
        if (!this.counting) {
          return;
        }

        this.setRemain(this.getRemain());
        if (this.remain > 0) {
          this.tick();
        }
      }, 1000)
    },


    getRemain() {
      return this.remain - 1
    },

    setRemain(remain) {
      this.remain = remain;
      this.$emit('change', this.timeData);

      if (remain === 0) {
        // this.pause();
        this.$emit('finish');
      }
    },

  },
}
</script>

<style scoped lang="scss">

.timer {
  @include flex_center;
white-space: nowrap;
  &-item {
    line-height: .53rem;
    font-size: .35rem;
    margin-right: .1rem;
    font-family: DIN-Medium;
  }

  .day {
    color: #FF6A4D;

    .number {
      font-weight: bold;
    }
  }

  .hour, .minute, .second {
    width: 0.48rem;
    height: 0.53rem;
    background: linear-gradient(141deg, #FF6A4D 0%, #FF904D 100%);
    border-radius: .08rem;
    color: #fff;
    text-align: center;
  }

  .symbol {
    color: #FF6A4D;
    font-weight: bold;
  }
}

</style>
