const formatSingleNumber = (number) => {
    return number <= 9 ? '0' + number : number
}

// 格式化日期
const formatDate = (time) => {
    const date = new Date(time * 1000),
        year = date.getFullYear(),
        month = formatSingleNumber(date.getMonth() + 1),
        day = formatSingleNumber(date.getDate()),
        hour = formatSingleNumber(date.getHours()),
        minute = formatSingleNumber(date.getMinutes()),
        second = formatSingleNumber(date.getSeconds()),
        md = month + '月' + day + '日',
        ms = minute + ':' + second,
        hm = hour + ':' + minute,
        hms = hour + ':' + ms

    return {
        year,
        month,
        day,
        hour,
        minute,
        second,
        md,
        ms,
        hm,
        hms,
    }
}
// 格式化时间戳
const formatTime = (time) => {
    if (time <= 0) time = 0;
    time = time >>> 0
    let day = parseInt(time / (3600 * 24)),
        hour = parseInt(time % (3600 * 24) / 3600),
        minute = parseInt((time % 3600) / 60),
        second = parseInt(time % 60),
        ms = formatSingleNumber(minute) + ':' + formatSingleNumber(second),
        hms = formatSingleNumber(hour) + ':' + ms
    return {
        day,
        hour,
        minute,
        second,
        ms,
        hms,
    }
}

module.exports = {
    formatDate,
    formatTime
}
