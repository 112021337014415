<template>
	<div style="flex:1">
		<van-overlay :show="show" @click="show = false" z-index="999999">
			<img class="image" :src="require('@/assets/images/common/share_tips.png')" />
		</van-overlay>
		<pageBottomButton color="red" buttonType="text" :text="text" @click="clickButton" />
	</div>
</template>

<script>
import pageBottomButton from '@/components/page-bottom-button'

export default {
	name: 'button-share',
	props: {
		text: String
	},
	components: {
		pageBottomButton,
	},
	data () {
		return {
			showShare: false,
			show: false,
		}
	},
	methods: {
		clickButton () {
			this.show = true
			this.$emit('click')
		},
	},
}
</script>

<style scoped lang="scss">
.image {
	position: absolute;
	top: 0.4rem;
	right: 0.6rem;
	width: 8.24rem;
}

@media screen and (min-width: $maxWidth) {
	::v-deep .van-overlay {
		z-index: 999999;
	}
}
</style>
