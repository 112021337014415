/**
 * @author alanzhang
 * @date 2016-07-15
 * @overview 在浏览器中打开native App;
 * @url https://github.com/AlanZhang001/H5CallUpNative
 */



// UA鉴定
var browser = {
    isAndroid: function () {
        return !!navigator.userAgent.match(/Android/i);
    },
    isMobileQQ: function () {
        var ua = navigator.userAgent;
        return /(iPad|iPhone|iPod).*? (IPad)?QQ\/([\d]+)/.test(ua) || /\bV1_AND_SQI?_([\d]+)(.*? QQ\/([\d]+))?/.test(
            ua);
    },
    isIOS: function () {
        return !!navigator.userAgent.match(/iPhone|iPad|iPod|Mac/i);
    },
    isWx: function () {
        return !!navigator.userAgent.match(/micromessenger/i);
    },
    isPC: function () {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
        var widowflag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                widowflag = false;
                break;
            }
        }
        return widowflag;
    },
}
var AppConfig = {

    // 协议头
    PROTOCAL: "",

    // 主页
    HOME: "",

    // 唤起失败时的跳转链接
    FAILBACK: {
        ANDROID: "",
        IOS: ""
    },

    // Android apk 相关信息
    APK_INFO: {
        PKG: "",
        CATEGORY: "android.intent.category.DEFAULT",
        ACTION: "android.intent.action.VIEW"
    },

    // 唤起超时时间，超时则跳转到下载页面
    LOAD_WAITING: 3000
};

var ua = window.navigator.userAgent;

// 是否为Android下的chrome浏览器，排除mobileQQ；
// Android下的chrome，需要通过特殊的intent 来唤醒
// refer link：https://developer.chrome.com/multidevice/android/intents
var isAndroidChrome = (ua.match(/Chrome\/([\d.]+)/) || ua.match(/CriOS\/([\d.]+)/)) &&
    browser.isAndroid() && !browser.isMobileQQ();


//浏览器提示
function browserTip() {

    //浏览器弹窗样式
    var style_el = document.createElement('style');
    style_el.innerHTML =
        `p{padding:0;margin:0}.modal_browser_tips{position:fixed;top:0;left:0;z-index:1050;display:none;width:100%;height:100%;outline:0}.modal-tip-bg{position:absolute;width:100%;height:100%;transition:all .3s linear;background-color:rgba(0,0,0,0)}.right-browser{position:absolute;top:0;right:14px;width:4rem;height:2.4rem;background:url(https://skbang-1253756937.cos.ap-guangzhou.myqcloud.com/active/right_browser.png
) no-repeat center;background-size:100%;opacity:0;animation-duration:1s;transform:scale(1)}.show{display:block!important}.modal-tip-bg.in{background-color:rgba(0,0,0,.6)}.right-browser.in{animation-duration:.6s;animation-name:bounceIn;opacity:1}@keyframes bounceIn{0%,50%,to{animation-timing-function:cubic-bezier(0.215,0.61,0.355,1)}0%{opacity:0;transform:scale3d(0.3,0.3,0.3)}50%{opacity:1;transform:scale3d(1.03,1.03,1.03)}to{opacity:1;transform:scalex(1)}}`;
    document.head.append(style_el);

    //绘制浏览器弹窗div
    let template =
        `<div class="modal_browser_tips"><div class="modal-tip-bg"><div class="right-browser"></div></div></div>`;

    document.querySelector("body").insertAdjacentHTML('beforeend', template);

    let dom_modal = document.querySelector(".modal_browser_tips"),
        dom_modalBackground = document.querySelector(".modal-tip-bg"),
        dom_modalTips = document.querySelector(".right-browser");

    //显示浏览器提示弹窗
    function showBrowserTips() {
        dom_modal.classList.add("show")
        dom_modalTips.classList.add("in");
        setTimeout(() => {
            dom_modalBackground.classList.add("in")
        }, 1)
    }

    //隐藏浏览器提示弹窗
    function hedeBrowserTips() {
        dom_modalBackground.classList.remove("in");
        dom_modalTips.classList.remove("in");
        setTimeout(() => {
            dom_modal.classList.remove("show");
        }, 600)
    }

    //绑定点击事件
    if (dom_modal) {
        dom_modal.addEventListener("click", function () {
            hedeBrowserTips();
        }, false)

    }

    this.show = function () {
        showBrowserTips();
    }
    this.hede = function () {
        hedeBrowserTips();
    }

}

/**
 * [mixinConfig 重新收拢配置]
 * @param  {[type]} config [description]
 * @return {[type]}        [description]
 */
function mixinConfig(config) {
    if (!config) {
        return;
    }

    AppConfig.PROTOCAL = config.protocal || AppConfig.PROTOCAL;
    AppConfig.schema = config.schema || AppConfig.HOME;
    AppConfig.LOAD_WAITING = config.loadWaiting || AppConfig.LOAD_WAITING;

    if (browser.isIOS()) {
        AppConfig.FAILBACK.IOS = config.failUrl || AppConfig.FAILBACK.IOS;
    } else if (browser.isAndroid()) {
        AppConfig.FAILBACK.ANDROID = config.failUrl || AppConfig.FAILBACK.ANDROID;
        AppConfig.APK_INFO = config.apkInfo || AppConfig.APK_INFO;
    }

}

/**
 * [generateSchema 根据不同的场景及UA生成最终应用的schema]
 * @return {[type]}                [description]
 */
function generateSchema(schema) {

    var localUrl = window.location.href;
    var schemaStr = '';

    // 如果未定义schema，则根据当前路径来映射
    if (!schema) {
        schemaStr = AppConfig.HOME;
        // 在schema省略时，可以根据当前页面的url，设置不同的默认值
    } else {
        schemaStr = schema;
    }

    // 如果是安卓chrome浏览器，则通过intent方式打开
    //				if(isAndroidChrome) {
    //					schemaStr = "intent://" + schemaStr + "#Intent;" +
    //						"scheme=" + AppConfig.PROTOCAL + ";" +
    //						"package=" + AppConfig.APK_INFO.PKG + ";" +
    //						"category=" + AppConfig.APK_INFO.CATEGORY + ";" +
    //						"action=" + AppConfig.APK_INFO.ACTION + ";" +
    //						"S.browser_fallback_url=" + encodeURIComponent(AppConfig.FAILBACK.ANDROID) + ";" +
    //						"end";
    //				} else {
    //					schemaStr = AppConfig.PROTOCAL + "://" + schemaStr;
    //				}
    schemaStr = AppConfig.PROTOCAL + "://" + schemaStr;

    return schemaStr;
}

var browserTips = ""
module.exports = {


    /**
     * [openSchema 唤醒native App，如果无法唤醒，则跳转到下载页]
     * @return {[type]} [description]
     */
    openSchema: function (config) {
        if (!browserTips) {
            browserTips = new browserTip();
        }

        mixinConfig(config);

        var schemaUrl = generateSchema(AppConfig.schema);

        var iframe = document.createElement("iframe"),
            aLink = document.createElement("a"),
            body = document.body,
            loadTimer = null;

        // 隐藏iframe及a
        aLink.style.cssText = iframe.style.cssText = "display:none;width:0px;height:0px;";

        // Android 微信不支持schema唤醒，必须提前加入腾讯的白名单
        if (browser.isWx()) {
            browserTips.show();
            return;
            // ios 9 safari 不支持iframe的方式跳转
        } else if (browser.isIOS()) {
            aLink.href = schemaUrl;
            body.appendChild(aLink);
            aLink.click();

            // Android chrome 不支持iframe 方式唤醒
            // 适用：chrome,leibao,mibrowser,opera,360
        }
            //				else if(isAndroidChrome) {
            //					aLink.href = schemaUrl;
            //					body.appendChild(aLink);
            //					aLink.click();
            //
            //					// 其他浏览器
            //					// 适用：UC,sogou,firefox,mobileQQ
        //				}
        else {
            body.appendChild(iframe);
            iframe.src = schemaUrl;
        }

        // 如果LOAD_WAITING时间后,还是无法唤醒app，则直接打开下载页
        // opera 无效
        var start = Date.now()

        loadTimer = setTimeout(function () {

            if (document.hidden || document.webkitHidden) {
                return;
            }

            // 如果app启动，浏览器最小化进入后台，则计时器存在推迟或者变慢的问题
            // 那么代码执行到此处时，时间间隔必然大于设置的定时时间
            if (Date.now() - start > AppConfig.LOAD_WAITING + 200) {
                // come back from app

                // 如果浏览器未因为app启动进入后台，则定时器会准时执行，故应该跳转到下载页
            } else {
                window.location.href = browser.isIOS() ? AppConfig.FAILBACK.IOS : AppConfig.FAILBACK.ANDROID;
            }

        }, AppConfig.LOAD_WAITING);

        // 当本地app被唤起，则页面会隐藏掉，就会触发pagehide与visibilitychange事件
        // 在部分浏览器中可行，网上提供方案，作hack处理
        var visibilitychange = function () {
            var tag = document.hidden || document.webkitHidden;
            tag && clearTimeout(loadTimer);
        };
        document.addEventListener('visibilitychange', visibilitychange, false);
        document.addEventListener('webkitvisibilitychange', visibilitychange, false);
        // pagehide 必须绑定到window
        window.addEventListener('pagehide', function () {
            clearTimeout(loadTimer);
        }, false);
    }
}


