// 更新ios meta
export function updateIosMeta () {
    window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.canLoadShare.postMessage(null);
}
// 更新安卓 meta
export function updateAndroidMeta () {
    window.tikuWeb && window.tikuWeb.setShareHtml('<head>' + document.getElementsByTagName('html')[0].innerHTML + '</head>')
}

// 更新app meta信息
export function updateAppMeta () {
    updateAndroidMeta()
    updateIosMeta()
}

// 获取设备版本号
export function getDeviceVersionNumber (version) {
    if (!version) {
        return 0
    }
    let number = Number(''.slice.call(version, 1))
    return number > 0 ? number : 0
}