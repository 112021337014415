<template>
  <van-button round type="info" :color="buttonColor" class="button" :disabled="disabled" @click="onClick" >
    <template v-if="buttonType==='price'">
      <div class="price">
        <span class="symbol">￥</span>
        <span class="number">{{ textPrice }}</span>
        <p class="text">{{ text }}</p>
      </div>
    </template>
    <template v-if="buttonType==='text'">
      <p class="text" style="font-size: .4rem">{{ text }}</p>
    </template>
  </van-button>
</template>

<script>
export default {
  name: "page-bottom-button",
  props: {
    buttonType: {
      type: String,
      default: 'text'
    },
    color: {
      type: String,
      default: 'red'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    },
    textPrice: {
      type: String
    }
  },
  data() {
    return {
      colors: {
        yellow: '#FFBB33',
        red: '#FF4D4D',
      }
    }
  },
  computed: {
    buttonColor() {
      return this.colors[this.color]
    }
  },
  methods: {
    onClick(res) {
      this.$emit('click', function (res) {
        return res
      })
    }
  },
}
</script>

<style scoped lang="scss">
.button {
  height: 1.12rem;
  flex: 1;
  width: 100%;
  & + .button {
    margin-left: .27rem;
  }

  .price {
    font-size: 0.4rem;
  }

  .text {
    font-size: 0.29rem;
    color: #FFFFFF;
  }

}

</style>
