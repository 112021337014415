<template>
  <pageBottomButton color="red" buttonType="text" :text="buttonText" class="button" @click="openApp"/>
</template>

<script>
import pageBottomButton from '@/components/page-bottom-button'
import {openSchema} from '@/utils/schema'

export default {
  props: ['brand', 'no', 'type', 'province', 'showBrandName'],
  name: "button-app",
  components: {
    pageBottomButton,
  },
  data() {
    return {
      brandList: {
        skb: {
          protocal: 'skb',
          failUrl: 'shikaobang',
          text: '事考帮',
        },
        jbcgk: {
          protocal: 'jbcgk',
          failUrl: 'jbcgk',
          text: '金标尺公考',

        },
        gpjs: {
          protocal: '',
          failUrl: 'gpjiaoshi',
          text: '金标尺教师',
        }
      },
      schemaUnitKeys: {
        course: 'CourseDetail',
        question_set: 'QuestionSetDetail',
        identity: 'IdentityDetail',
      }
    }
  },
  computed: {
    currentBrand() {
      return this.brandList[this.brand] || {}
    },
    currentUnitKey() {
      return this.schemaUnitKeys[this.type]
    },
    buttonText() {
      return this.showBrandName ? `打开${this.currentBrand.text}APP学习` : `打开APP学习`

    }
  },
  mounted() {
    this.brandList.gpjs.protocal = !(navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1) ? 'gpjiaoshi' : 'gpjs'
  },
  methods: {
    // 唤醒APP
    openAPPSchema(protocal, failUrl, schema) {
      openSchema({
        protocal: protocal,
        schema: 'openUnit/schema?params=' + JSON.stringify(schema),
        failUrl: `https://a.app.qq.com/o/simple.jsp?pkgname=cn.net.tiku.${failUrl}.syn`,
      })
    },
    // 执行打开app方法
    openApp() {
      let protocal = this.currentBrand.protocal
      let failUrl = this.currentBrand.failUrl

      let no = this.no
      let appkey = `${protocal}.${this.province}`,
          unitKey = this.currentUnitKey


      let schema = {
        type: 'openUnit',
        option: {
          unitKey,
          param: {no, appkey},
        },
      }

      // app内打开，且商品地区key与当前选择key不一致时
      if (this.$store.getters.isDeviceAppClient && (this.$store.state.setup.provinceKey !== this.province)) {
        delete schema.option.param.appkey
      }


      this.openAPPSchema(protocal, failUrl, schema)
      this.$emit('onClick')
    },

  }
}
</script>

<style scoped>
.button {
  width: 100%;
}
</style>
